<script>
	import { page } from '$app/stores';
	import { browser } from '$app/environment';
	import { PUBLIC_SHYNET_ID, PUBLIC_SHYNET_URL } from '$env/static/public';

	/* See https://github.com/sveltejs/kit/issues/3127#issuecomment-1225840065 */
	import '$lib/styles/global.css';
	import { ShynetTrackingScript } from 'svelte-utilities';

	$: location = browser ? $page.url : undefined;
</script>

<svelte:head>
	<meta
		name="description"
		content={`Shape Exchange - organization and configuration of shapes for construction`}
	/>
	<title>Shape Exchange</title>
</svelte:head>

<slot />
<ShynetTrackingScript url={PUBLIC_SHYNET_URL} serviceID={PUBLIC_SHYNET_ID} {location} />
